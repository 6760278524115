import React, { useEffect } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { navigate } from '@reach/router';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const funeralInfoPage = () => {
  navigate('/funeralInfo');
};

const EdwinsGallery = () => {
  navigate('/gallery');
};

const SliderMain = () => {

  useEffect(() => {
    // Load the Cloudinary widget script
    const script = document.createElement('script');
    script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openCloudinaryWidget = () => {
    window.cloudinary.openUploadWidget({
      cloudName: 'dkgygs5gq', // replace with your Cloudinary cloud name
      uploadPreset: 'Edwin0', // replace with your upload preset
      sources: ['local', 'url'],
      multiple: true,
      resourceType: 'auto',
      tags: ['Edwin', 'gallery-images'], // General tags for all media
      context: { alt: 'Gallery media' }, // Optional: Adding context information
    }, (error, result) => {
      if (!error && result && result.event === "success") {
        console.log('Upload successful:', result.info);
        if (result.info.resource_type === "video") {
          // Add additional tag for videos
          window.cloudinary.v2.uploader.add_tag("gallery-videos", [result.info.public_id], () => {});
        }
        // Handle successful upload here
      } else if (error) {
        console.error('Upload error:', error);
      }
    });
  };
  

  return (
    
    <div className="container margin-top-header">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="spacer-single"></div>
          
          <div className="spacer-10"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
            <h1>Upload your memories of Edwin Bertram here.</h1>
          </Reveal>
          <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
            <h4><p className="lead">Click the upload button.</p></h4>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
            <button onClick={openCloudinaryWidget} className="btn-main lead">Upload Images & Videos</button>
            <div className="mb-sm-30"></div>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
            <button onClick={funeralInfoPage} className="btn-main lead">Funeral Info</button>
            <div className="mb-sm-30"></div>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
            <button onClick={EdwinsGallery} className="btn-main lead">Gallery</button>
            <div className="mb-sm-30"></div>
          </Reveal>
        </div>
        <div className="col-md-6 xs-hide">
          <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
          <img src="./img/misc/floating_img.png" className="lazy img-fluid" alt=""/>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default SliderMain;
