import React, { Component } from "react";
import styled from "styled-components";
import Clock from "./Clock";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {
    dummyData = [
    {
        deadline:"August, 29, 2024",
        authorLink: "#",
        previewLink: "#",
        nftLink: "https://www.google.com/maps/@52.9855884,-1.1991274,3a,75y,88.02h,82.52t/data=!3m7!1e1!3m5!1sMR8Ts573vm0jGhkGcbyOhQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DMR8Ts573vm0jGhkGcbyOhQ%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D88.02080314988609%26pitch%3D7.481622484494039%26thumbfov%3D90!7i13312!8i6656?coh=205410&entry=ttu",
        bidLink: "https://www.google.com/maps/@52.9855884,-1.1991274,3a,75y,88.02h,82.52t/data=!3m7!1e1!3m5!1sMR8Ts573vm0jGhkGcbyOhQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DMR8Ts573vm0jGhkGcbyOhQ%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D88.02080314988609%26pitch%3D7.481622484494039%26thumbfov%3D90!7i13312!8i6656?coh=205410&entry=ttu",
        authorImg: "./img/author/author-1.jpg",
        previewImg: "./img/funeral_info/wake.jpg",
        title: "Location",
        
    },
    ]

  constructor(props) {
    super(props);
    this.state = {
        nfts: this.dummyData,
        height: 0
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    }

     onImgLoad({target:img}) {
        let currentHeight = this.state.height;
        if(currentHeight < img.offsetHeight) {
            this.setState({
                height: img.offsetHeight
            })
        }
    }

 render() {
  return (
    <div className='row'>
        {this.state.nfts.map( (nft, index) => (
            <div key={index} className="d-item centered-content">
                    <div className="nft__item">
                    { nft.deadline &&
                    <div className="de_countdown">
                    <Clock deadline={nft.deadline} />
                    </div>
                    }
                    <div className="author_list_pp">
                        <span onClick={()=> window.open(nft.authorLink, "_self")}>                                    
                            <img className="lazy" src={nft.authorImg} alt=""/>
                            <i className="fa fa-check"></i>
                        </span>
                    </div>
                    <div className="nft__item_wrap" style={{height: `${this.state.height}px`}}>
                      <Outer>
                        <span onClick={()=> window.open(nft.previewLink, "_self")}>
                            <img onLoad={this.onImgLoad} src={nft.previewImg} className="lazy nft__item_preview" alt=""/>
                        </span>
                      </Outer>
                    </div>
                    <div className="nft__item_info">
                        <span onClick={()=> window.open(nft.nftLink, "_self")}>
                            <h4>{nft.title}</h4>
                        </span>
                        <div className="nft__item_price">
                            {nft.price}<span>{nft.bid}</span>
                        </div>
                        <div className="nft__item_action">
                            <span onClick={()=> window.open(nft.bidLink, "_self")}>click "location for the map"</span>
                        </div>
                        <div className="nft__item_like">
                            
                        </div>                            
                    </div> 
                </div>
                </div>  
             
        ))}
        
    </div>              
    );
}
}