import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Image, Video, Transformation } from 'cloudinary-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./styles.css";

export const GalleryWidget = ({ config }) => {
  const { cloudName, mediaAssets, displayProps } = config;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: i => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "#000",
        }}
      ></div>
    ),
    
  };

  return (
    <Slider {...settings}>
      {mediaAssets.map((media, index) => {
        const { mediaType = '', publicId, tags = [] } = media;
        const isVideo = mediaType === "video" || tags.includes("gallery-videos");

        return (
          <div key={index} className="media-item">
            {isVideo ? (
              <Video
                cloudName={cloudName}
                publicId={publicId}
                controls
                width="auto"
                height="auto"
              >
                <Transformation quality="auto" fetchFormat="auto" />
              </Video>
            ) : (
              <Image
                cloudName={cloudName}
                publicId={publicId}
                width="100%"
                height="auto"
                alt={`media-${index}`}
              >
                <Transformation quality="auto" fetchFormat="auto" />
              </Image>
            )}
          </div>
        );
      })}
    </Slider>
  );
};

GalleryWidget.propTypes = {
  config: PropTypes.shape({
    cloudName: PropTypes.string.isRequired,
    mediaAssets: PropTypes.arrayOf(
      PropTypes.shape({
        publicId: PropTypes.string.isRequired,
        mediaType: PropTypes.string, // Not marked as required
        tags: PropTypes.arrayOf(PropTypes.string),
        transformation: PropTypes.string,
      })
    ).isRequired,
    displayProps: PropTypes.shape({
      mode: PropTypes.string,
      columns: PropTypes.number,
    }),
  }).isRequired,
};

export default GalleryWidget;
