import React, { useEffect, useState } from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GalleryWidget } from "./GalleryWidget";
import "./styles.css";

const cloudName = "dkgygs5gq"; // Replace with your own cloud name

export default function App() {
  const [mediaAssets, setMediaAssets] = useState([]);
  const [assetCount, setAssetCount] = useState(0);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        // Fetch image assets
        const imageResponse = await fetch(`https://res.cloudinary.com/${cloudName}/image/list/Edwin.json`);
        const imageData = await imageResponse.json();
        
        // Fetch video assets
        const videoResponse = await fetch(`https://res.cloudinary.com/${cloudName}/video/list/Edwin.json`);
        const videoData = await videoResponse.json();

        // Combine image and video assets
        const combinedAssets = [
          ...(imageData.resources || []).map(asset => ({ ...asset, mediaType: 'image' })),
          ...(videoData.resources || []).map(asset => ({ ...asset, mediaType: 'video' }))
        ];

        setMediaAssets(combinedAssets);
        setAssetCount(combinedAssets.length);
      } catch (error) {
        console.error('Error fetching media assets:', error);
      }
    };

    fetchAssets();
  }, []);

  const config = {
    cloudName: cloudName,
    mediaAssets: mediaAssets.map(media => ({
      publicId: media.public_id,
      mediaType: media.mediaType, // Use the mediaType added during data processing
      transformation: media.mediaType === "image" ? "q_auto,f_auto" : "", // Specific transformations
    })),
    
    displayProps: { mode: "expanded", columns: 2 },
    imageBreakpoint: 200,
    borderWidth: 5,
    transition: "fade",
    zoom: true,
  };

  return (
    <div className="App">
      <main className="main">
        <div className="container">
        <div className="spacer-10"></div>
        <div className="spacer-single"></div>
        <div className="spacer-single"></div>
        <div className="spacer-single"></div>
        <div className="spacer-single"></div>
        
          <h1 className="title">Welcome to Edwin's Gallery</h1>
          <p>Here, we celebrate the beautiful moments captured over time. Enjoy browsing through the memories!</p>
          <p>Total media items: {assetCount}</p>
        </div>
        <div className="container">
          {mediaAssets.length > 0 && (
            <GalleryWidget config={config} />
          )}
        </div>
      </main>
    </div>
  );
}
