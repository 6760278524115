import React from 'react';
import { Link } from '@reach/router';

const Footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="widget">
        <h5>We want to thank you for uploading your memories to enable us to create a unique slideshow of them all.</h5>
      </div>
      <div className="widget">
        <h5>This is to put all images into one area, from members of the family and friends who are spread across the globe. Then a slideshow can be done from all the images / videos collected.</h5>
      </div>
      <div className="widget">
        <h5>If you like this idea? contact Keith to create yours. Whatsapp: +447510786225</h5>
      </div>
    </div>
  </footer>
);

export default Footer;
