import React from 'react';
import SliderMain from '../components/SliderMain';
import CarouselNew from '../components/CarouselNew';
import Footer from '../components/footer';


const home= () => (
  <div>
      <section className="jumbotron breadcumb no-bg h-vh" style={{backgroundImage: `url(${'./img/background_main.png'})`}}>
      
         <SliderMain/>
      </section>
      

      

      

    <Footer />

  </div>
);
export default home;